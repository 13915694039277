"use client";

import { LoadingSvg } from "@/public/svg";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

export default function Button({
  label = "Text Here",
  size = "medium",
  variants = "solid",
  colors = "primary",
  className = "",
  loading = false,
  disable = false,
  icon = { right: undefined, left: undefined },
  onClick = () => {},
  onClickText = () => {},
  onHover = {
    start: () => {},
    end: () => {}
  },
  onHoverText = {
    start: () => {},
    end: () => {}
  },
  fontSize = "",
  ref,
  id = ""
}) {
  const styles = useMemo(() => {
    const liseSize = {
      text: {
        small: "text-button-small",
        medium: "text-button-small xs:text-button-medium",
        large: "text-button-medium xs:text-button-medium"
      },
      icon: {
        small: "h-icon-small w-icon-small",
        medium: "h-icon-small w-icon-small xs:h-icon-medium xs:w-icon-medium",
        large: "h-icon-medium w-icon-medium xs:h-icon-large xs:w-icon-large"
      },
      padding: {
        small: "py-2 px-3",
        medium: "py-2 px-3 xs:py-3 xs:px-4",
        large: "py-3 px-4 xs:py-4 xs:px-5"
      }
    };

    const nameColors = `${colors}GON`;
    const classButton = {
      solid: {
        active: `bg-${nameColors}-400 border-${nameColors}-400  hover:bg-${nameColors}-500 focus-visible:bg-${nameColors}-700  text-shadesGON-50`,
        disable: `bg-${nameColors}-100 text-shadesGON-50 cursor-not-allowed`
      },
      outline: {
        active: `bg-shadesGON-50 border-${nameColors}-400 hover:bg-${nameColors}-50 focus-visible:bg-${nameColors}-100  text-${nameColors}-400`,
        disable: `border-${nameColors}-50 text-${nameColors}-50 cursor-not-allowed`
      },
      text: {
        active: `border-transparent hover:text-${nameColors}-500 focus-visible:text-${nameColors}-700  text-${nameColors}-400`,
        disable: `border-transparent text-${nameColors}-50 cursor-not-allowed`
      }
    };

    return {
      nameColors: nameColors,
      isText: liseSize?.text?.[size],
      isIcon: liseSize?.icon?.[size],
      padding: liseSize?.padding?.[size],
      styleButton: classButton?.[variants]?.active,
      styleButtonDisable: classButton?.[variants]?.disable
    };
  }, [size, colors, variants]);

  const IconButton = {
    left: icon?.left,
    right: icon?.right
  };

  return (
    <div
      ref={ref}
      id={id}
      tabIndex={0}
      onMouseEnter={() => onHover?.start && onHover?.start()}
      onMouseLeave={() => onHover?.end && onHover?.end()}
      onClick={(e) => {
        e.preventDefault();
        !disable && onClick && onClick(e);
      }}
      className={twMerge(
        `rounded flex justify-center items-center gap-2 border cursor-pointer select-none`,
        styles?.padding,
        disable ? styles.styleButtonDisable : styles?.styleButton,
        className
      )}
    >
      {loading ? (
        <>
          <div className={twMerge(styles?.isText, "animate-spin ")}>
            <LoadingSvg className={styles.isIcon} />
          </div>
          <span
            onClick={() => onClickText && onClickText()}
            className={twMerge(
              styles?.isText,
              `text-center capitalize max-w-full whitespace-nowrap overflow-hidden text-ellipsis`,
              fontSize
            )}
          >
            {label}
          </span>
        </>
      ) : (
        <>
          <div>
            {!icon?.left
              ? null
              : icon.left({
                  className: styles.isIcon
                })}
          </div>
          <span
            onClick={() => onClickText && onClickText()}
            onMouseEnter={() => onHoverText?.start && onHoverText?.start()}
            onMouseLeave={() => onHoverText?.end && onHoverText?.end()}
            className={twMerge(
              styles?.isText,
              `text-center capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis`,
              fontSize
            )}
          >
            {label}
          </span>
          <div>
            {!icon?.right
              ? null
              : icon.right({
                  className: styles.isIcon
                })}
          </div>
        </>
      )}
    </div>
  );
}

export function ButtonLink({
  id = "",
  label = "Text Here",
  onClick = () => {},
  size = "medium",
  colors = "primary",
  icon = { right: undefined, left: undefined },
  className = "",
  disable = false
}) {
  const styles = useMemo(() => {
    const liseSize = {
      text: {
        small: "text-button-small",
        medium: "text-button-small xs:text-button-medium",
        large: "text-button-medium xs:text-button-medium"
      },
      icon: {
        small: "h-icon-small w-icon-small",
        medium: "h-icon-small w-icon-small xs:h-icon-medium xs:w-icon-medium",
        large: "h-icon-medium w-icon-medium xs:h-icon-large xs:w-icon-large"
      },
      padding: {
        small: "py-2 px-3",
        medium: "py-2 px-3 xs:py-3 xs:px-4",
        large: "py-3 px-4 xs:py-4 xs:px-5"
      }
    };

    const nameColors = `${colors}GON`;
    const classButton = {
      active: `border-transparent hover:text-${nameColors}-500 focus-visible:text-${nameColors}-700  text-${nameColors}-400`,
      disable: `border-transparent text-${nameColors}-50 cursor-not-allowed`
    };

    return {
      nameColors: nameColors,
      isText: liseSize?.text?.[size],
      isIcon: liseSize?.icon?.[size],
      padding: liseSize?.padding?.[size],
      styleButton: classButton?.active,
      styleButtonDisable: classButton?.disable
    };
  }, [size, colors]);

  const { colorText, textSize } = useMemo(() => {
    return {
      textSize:
        size == "large"
          ? "subheading-medium"
          : size == "medium"
          ? "p1-medium"
          : "p2-medium",
      colorText: `text-${colors}GON-400 hover:text-${colors}GON-500 focus:text-${colors}GON-70`
    };
  }, [colors, size]);

  return (
    <div
      id={id}
      onClick={() => onClick && onClick()}
      className={twMerge(
        `rounded flex items-center gap-2 border cursor-pointer select-none`,
        disable ? styles.styleButtonDisable : styles?.styleButton,
        className
      )}
    >
      {!icon?.left
        ? null
        : icon.left({
            className: styles.isIcon
          })}
      <p className={twMerge(colorText, `capitalize`, textSize, "underline")}>
        {label}
      </p>
      {!icon?.right
        ? null
        : icon.right({
            className: styles.isIcon
          })}
    </div>
  );
}
