export { default as NotifOutlineSvg } from "./notif-outline.svg";
export { default as NotifSolidSvg } from "./notif-solid.svg";
export { default as SendSvg } from "./send.svg";
export { default as CheckboxUncheckSvg } from "./checkbox-uncheck.svg";
export { default as CheckboxCheckedSvg } from "./checkbox-checked.svg";
export { default as RadioUncheckSvg } from "./radio-uncheck.svg";
export { default as RadioCheckedSvg } from "./radio-checked.svg";
export { default as ToggleOffSvg } from "./toggle-off.svg";
export { default as ToggleOnSvg } from "./toggle-on.svg";
export { default as BuySvg } from "./buy.svg";
export { default as BuyOutlineSvg } from "./buy-outline.svg";
export { default as ArrowRightSvg } from "./arrow-right.svg";
export { default as ArrowLeftSvg } from "./arrow-left.svg";
export { default as ArrowUpSvg } from "./arrow-up.svg";
export { default as ArrowDownSvg } from "./arrow-down.svg";
export { default as PeopleSolidSvg } from "./people-solid.svg";
export { default as PeopleOutlineSvg } from "./people-outline.svg";
export { default as CloseSolidSvg } from "./close-solid.svg";
export { default as CloseOutlineSvg } from "./close-outline.svg";
export { default as ChevronRightSvg } from "./chevron-right.svg";
export { default as ChevronLeftSvg } from "./chevron-left.svg";
export { default as ChevronUpSvg } from "./chevron-up.svg";
export { default as ChevronDownSvg } from "./chevron-down.svg";
export { default as DeliverySvg } from "./delivery.svg";
export { default as DeliveryOutlineSvg } from "./delivery-outline.svg";
export { default as FastDeliveryOutlineSvg } from "./fastDelivery-outline.svg";
export { default as SaleSvg } from "./sale.svg";
export { default as IconGonSvg } from "./iconGon.svg";
export { default as IconGonMiniSvg } from "./iconGon-mini.svg";
export { default as EyeOnSvg } from "./eye-on.svg";
export { default as EyeOffSvg } from "./eye-off.svg";
export { default as CartOutlineSvg } from "./cart-outline.svg";
export { default as CartSolidSvg } from "./cart-solid.svg";
export { default as SearchSvg } from "./search.svg";
export { default as PaperSvg } from "./paper.svg";
export { default as PaperOutlineSvg } from "./paper-outline.svg";
export { default as BagSvg } from "./bag.svg";
export { default as BagOutlineSvg } from "./bag-outline.svg";
export { default as TransactionSvg } from "./transaction.svg";
export { default as TransactionOutlineSvg } from "./transaction-outline.svg";
export { default as UnlockSvg } from "./unlock.svg";
export { default as LogoutSvg } from "./logout.svg";
export { default as BarSvg } from "./bar.svg";
export { default as LoginSolidSvg } from "./login-solid.svg";
export { default as LoginOutlineSvg } from "./login-outline.svg";
export { default as HotSolidSvg } from "./hot-solid.svg";
export { default as HotOutlineSvg } from "./hot-outline.svg";
export { default as StarSolidSvg } from "./star-solid.svg";
export { default as StarOutlineSvg } from "./star-outline.svg";
export { default as ShieldSolidSvg } from "./shield-solid.svg";
export { default as ShieldOutlineSvg } from "./shield-outline.svg";
export { default as MinusSolidSvg } from "./minus-solid.svg";
export { default as SettingOutlineSvg } from "./setting-outline.svg";
export { default as MinusOutlineSvg } from "./minus-outline.svg";
export { default as PlusSolidSvg } from "./plus-solid.svg";
export { default as PlusOutlineSvg } from "./plus-outline.svg";
export { default as MapSolidSvg } from "./map-solid.svg";
export { default as MapOutlineSvg } from "./map-outline.svg";
export { default as DeleteSolidSvg } from "./delete-solid.svg";
export { default as DeleteOutlineSvg } from "./delete-outline.svg";
export { default as VoucherSolidSvg } from "./voucher-solid.svg";
export { default as VoucherOutlineSvg } from "./voucher-outline.svg";
export { default as MapPaperSolidSvg } from "./map-paper-solid.svg";
export { default as MapPaperOutlineSvg } from "./map-paper-outline.svg";
export { default as BasketOutlineSvg } from "./basket-outline.svg";
export { default as GiftOutlineSvg } from "./gift-outline.svg";
export { default as WalletOutlineSvg } from "./wallet-outline.svg";
export { default as WalletSolidSvg } from "./wallet-solid.svg";
export { default as PaymentOutlineSvg } from "./payment-outline.svg";
export { default as IncreaseOutlineSvg } from "./increase-outline.svg";
export { default as DecreaseOutlineSvg } from "./decrease-outline.svg";
export { default as FilterSolidSvg } from "./filter-solid.svg";
export { default as FilterOutlineSvg } from "./filter-outline.svg";
export { default as CategorySolidSvg } from "./category-solid.svg";
export { default as GroupSolidSvg } from "./group-solid.svg";
export { default as CalendarOutlineSvg } from "./calendar-outline.svg";
export { default as PaperDownloadSolidSvg } from "./paper-download-solid.svg";
export { default as PaperDownloadOutlineSvg } from "./paper-download-outline.svg";
export { default as Copyright } from "./copyright.svg";
export { default as CameraSolidSvg } from "./camera-solid.svg";
export { default as PenEditSvg } from "./pen-edit.svg";
export { default as PenEditSolidSvg } from "./pen-edit-solid.svg";
export { default as RefreshSvg } from "./refresh.svg";
export { default as SaveSolidSvg } from "./save-solid.svg";
export { default as WarningSolidSvg } from "./warning-solid.svg";
export { default as AcceptSvg } from "./accept.svg";
export { default as RemoveSvg } from "./remove.svg";
export { default as LoadingSvg } from "./loading.svg";
export { default as ImageSvg } from "./image.svg";
export { default as MoneyTransferSvg } from "./money-transfer.svg";
export { default as CopySvg } from "./copy.svg";
export { default as GiveMoneySvg } from "./give-money.svg";
export { default as MessageSolidSvg } from "./message-solid.svg";
export { default as MessageOutlineSvg } from "./message-outline.svg";

// sosial media svg
export { default as FbSvg } from "./sosial-media/fb.svg";
export { default as IgSvg } from "./sosial-media/ig.svg";
export { default as TwitterSvg } from "./sosial-media/twitter.svg";
export { default as TiktokSvg } from "./sosial-media/tiktok.svg";
export { default as WaSvg } from "./sosial-media/wa.svg";

// sosial media 3d svg
export { default as Fb3DSvg } from "./sosial-media-3d/fb.svg";
export { default as Ig3DSvg } from "./sosial-media-3d/ig.svg";
export { default as Twitter3DSvg } from "./sosial-media-3d/twitter.svg";
export { default as Tiktok3DSvg } from "./sosial-media-3d/tiktok.svg";
export { default as Wa3DSvg } from "./sosial-media-3d/wa.svg";

//footer
export { default as ShieldDone } from "./shield-done.svg";
export { default as FooterDelivery } from "./footer-delivery.svg";
export { default as CostumerService } from "./costumer-service.svg";
export { default as FooterPersentage } from "./footer-persentage.svg";
