"use client";

import Button from "@/components/atom/Button";

export default function Error({ error, reset }) {
  return (
    <div className="mx-auto my-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 md:p-12">
      <h2 className="text-xl font-bold">Oh tidak!</h2>
      <p className="my-2">
        Ada masalah dengan etalase kami. Ini mungkin merupakan masalah
        sementara, silakan coba tindakan Anda lagi. &nbsp;
        <span className="font-semibold">{error.message}</span>
      </p>
      <Button onClick={() => reset()} label="Coba Lagi" />
    </div>
  );
}
